import { useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Select from "react-select";

import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";

import LoadingText from "../../UI/LoadingText/LoadingText";

import { useCreateProcessMutation } from "../../../features/process/processApiSlice";
import { useUpdateCalibrationMutation } from "../../../features/bion/bionApiSlice";

import { SiFuturelearn as BionicIcon } from "react-icons/si";
import { FaCopy as ReplicationIcon } from "react-icons/fa";
import { IoCreateSharp as CreationIcon } from "react-icons/io5";
import { CgCalibrate as CalibrationIcon } from "react-icons/cg";

import BionImg from "../../../assets/images/bion.png";

import styles from "./CreateProcess.module.css";
import CalibrateModal from "../CalibrateModal/CalibrateModal";
import ProcessPlanner from "./ProcessPlanner/ProcessPlanner";

const CreateProcess = ({ bion }) => {
  const [creationIsOpen, setCreationIsOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [promptMode, setPromptMode] = useState(null);

  const [calibrationIsOpen, setCalibrationIsOpen] = useState(
    bion?.bionMode === "CALIBRATION_ACTIVE"
  );

  const [updateCalibrationReq] = useUpdateCalibrationMutation();

  const [selectedType, setSelectedType] = useState();
  const processNameRef = useRef();
  const descriptionRef = useRef();

  const [createProcessReq, { isLoading: createProcessReqIsLoading }] =
    useCreateProcessMutation();

  const toggleCreation = () => {
    setCreationIsOpen((prevState) => !prevState);
  };

  const closeCalibration = (deactivateCalibration = true) => {
    setCalibrationIsOpen(false);

    if (!deactivateCalibration) return;

    updateCalibrationReq({
      bionId: bion.bionId,
      calibrationActive: false,
      sensorType: "PH",
    });
  };

  const openCalibration = (event) => {
    event.preventDefault();
    setCalibrationIsOpen(true);
  };

  const createProcessHandler = () => {
    // setCalibrationIsOpen(true);
    createProcessReq({
      bionId: bion.bionId,
      processName: processNameRef.current.value,
      description: descriptionRef.current.value,
      processType: selectedType,
      endDate: endDate ? endDate.$d.toISOString() : null,
    });
  };

  // const processNameChangeHandler = (event) => {
  //   setProcessName(event.target.value);
  // };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    createProcessHandler();
  };

  const isDateMoreThan30DaysAgo = (date) => {
    return false;
    if (!date) return true;

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return new Date(date) < thirtyDaysAgo;
  };

  const promptBtnClickHandler = (title) => {
    setPromptMode(title);
  };

  return (
    <div className={styles["page"]}>
      <>
        <AnimatePresence>
          {calibrationIsOpen && (
            <CalibrateModal
              onClose={closeCalibration}
              bion={bion}
              createProcess={createProcessHandler}
            />
          )}
        </AnimatePresence>
        {creationIsOpen ? (
          <>
            {/* <div
              className={`${styles["overlay"]} ${
                promptMode ? styles["active"] : ""
              }`}
              onClick={() => {
                setPromptMode(null);
              }}
            ></div> */}
            {/* Media / Lines */}
            {/* <div
              className={`${styles["define-process-modal"]} ${
                promptMode ? styles["active"] : ""
              }`}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "Center",
              }}
            > */}
            {/* First Step */}
            {/* <h2 className={styles["prompt-title"]}>Mode Selection</h2>
              <div className={styles["media-btn-row-1"]}>
                <button>Batch</button>
                <button>Fed-Batch</button>
                <button>Continuous</button>
              </div> */}

            {/* Third Step <= Lines */}
            {/* <h2 className={styles["prompt-title"]}>
                Lines Design | Batch Mode
              </h2>
              <p className={styles["prompt"]}>
                Correlate the{" "}
                <span className={styles["line-name"]}>Line 1</span> with the
                group <span>Carbon source</span>. This group start from a
                concentration of <span></span> (mg/L) and the maximum volume of
                the group <span></span>(ml).
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "28px",
                  marginTop: "28px",
                }}
              >
                <button className={styles["add-line-btn"]}>+</button>
                <p className={`${styles["prompt"]} ${styles["fake"]}`}>
                  Correlate the{" "}
                  <span className={styles["line-name"]}>Line 2</span> with the
                  group <span> </span>.
                </p>
              </div> */}

            {/* Fourth Step */}
            {/* <h2 className={styles["prompt-title"]}>
                Target Lines Concentration | Batch Mode
              </h2>
              <p className={styles["prompt"]}>
                Design a bioprocess with a total production volume of{" "}
                <span>250 - 2,000</span>L.
                <br />
                <br />
                In the <span className={styles["line-name"]}>line 1</span> add <span>___</span>mg/L
                concentration of (min)___ (max)mg/L. 
              </p> */}

            {/* Fifth Step */}
            {/* <h2
                className={styles["prompt-title"]}
                style={{ position: "absolute", top: "48px", left: "48px" }}
              >
                Lines Results | Batch Mode
              </h2>
              <p className={styles["prompt"]} style={{ textAlign: "center" }}>
                Lines design happend{" "}
                <span
                  style={{
                    color: "#1e90ff",
                    border: 0,
                    padding: 0,
                    fontWeight: "900",
                    fontSize: "60px",
                  }}
                >
                  succesfully
                </span>
                !
              </p> */}

            {/* Sixth Step */}
            {/* <div
                style={{
                  display: "flex",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <h2 className={styles["prompt-title"]}>
                  Injection Design | Batch Mode
                </h2>
                <button
                  style={{
                    background: "#fefefe",
                    color: "#101010",
                    padding: "12px 28px",
                    borderRadius: "16px",
                    fontSize: "32px",
                    marginBottom: "30px",
                  }}
                >
                  Skip
                </button>
              </div>
              <p className={styles["prompt"]}>
                I desire to inject ___ with a concentration of ___ mg/L. The
                target injection rate will be ___% over the total volume.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "28px",
                  marginTop: "28px",
                }}
              >
                <button className={styles["add-line-btn"]}>+</button>
                <p className={`${styles["prompt"]} ${styles["fake"]}`}>
                  I desire to inject ___ with a concentration of ___ mg/L. The
                  target injection rate will be ___% over the total volume.
                </p>
              </div> */}

            {/* SeventhStep INjection FedBatch */}
            {/* <div
                style={{
                  display: "flex",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <h2 className={styles["prompt-title"]}>
                  Injection Design | Batch Mode
                </h2>
                <button
                  style={{
                    background: "#fefefe",
                    color: "#101010",
                    padding: "12px 28px",
                    borderRadius: "16px",
                    fontSize: "32px",
                    marginBottom: "30px",
                  }}
                >
                  Skip
                </button>
              </div>
              <p className={styles["prompt"]}>
                I desire to inject <span>___</span> with a concentration of ___
                mg/L. The target injection rate will be ___% over the total
                volume.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "28px",
                  marginTop: "28px",
                }}
              >
                <button className={styles["add-line-btn"]}>+</button>
                <p className={`${styles["prompt"]} ${styles["fake"]}`}>
                  I desire to inject ___ with a concentration of ___ mg/L. The
                  target injection rate will be ___% over the total volume.
                </p>
              </div>

              <div
                className={`${styles["btn-row"]} ${styles["btn-row-fixed"]}`}
              >
                <button onClick={() => setPromptMode(null)}>Cancel</button>
                <button>Accept</button>
              </div> */}

            {/* Seventh Step */}
            {/* <h2
                className={styles["prompt-title"]}
                style={{ position: "absolute", left: "48px", top: "48px" }}
              >
                Injection Results | Batch Mode
              </h2>
              <p className={styles["prompt"]} style={{ textAlign: "center" }}>
                Injection design happend{" "}
                <span
                  style={{
                    color: "#1e90ff",
                    border: 0,
                    padding: 0,
                    fontWeight: "900",
                    fontSize: "60px",
                  }}
                >
                  succesfully
                </span>
                !
              </p> */}

            {/* <div className={styles["circles"]}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div> */}
            {/* <p className={styles["description"]}>
                Design a bioprocess that changes everything!
              </p>

              <p className={styles["prompt"]}>
                I would like to design a <span>Cell growth</span> process that
                aims to <span>Maximize product yield</span> in a controlled
                environment. This process is designed for application in the
                industry of <span>Food and beverage</span> to respond the
                growing demand for <span>Sustainable materials</span>. For this
                purpose the <span>Yeasts</span> have been chose due to their{" "}
                <span>Rapid growth rate</span>. All procedures comply with{" "}
                <span>GMP standards</span> ensuring the safe handling of{" "}
                <span>Cell cultures</span>
              </p> */}

            {/* <div className={styles["btn-row"]}>
                <button>Cancel</button>
                <button>Confirm</button>
              </div> */}
            {/* </div> */}

            {/* <h1 className={styles["title"]}>
              <img src={BionImg} alt="" />
              Create process for {bion.bionName}
            </h1>
            <div className={styles["creation-schema"]}>
              <button
                class={styles["main-btn"]}
                onClick={promptBtnClickHandler.bind(null, "Process")}
              >
                Define Bioprocess
              </button>
              <div className={styles["row"]}>
                <button onClick={promptBtnClickHandler.bind(null, "Media")}>
                  Media Integration
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "Thermal")}>
                  Thermal Control
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "Agitation")}>
                  Agitation Control
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "Magnetic")}>
                  Magnetic Stirring Control
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "PH")}>
                  PH Control
                </button>
              </div>
              <div className={styles["row"]}>
                <button
                  onClick={promptBtnClickHandler.bind(null, "Atmospheric")}
                >
                  Atmospheric Control
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "Exposure")}>
                  Exposure Control
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "Radiation")}>
                  Radiation Control
                </button>
                <button
                  onClick={promptBtnClickHandler.bind(null, "Ultrasound")}
                >
                  Ultrasound Control
                </button>
                <button onClick={promptBtnClickHandler.bind(null, "Sample")}>
                  Sample Extraction
                </button>
              </div>
              <button className={styles["create-process-btn"]}>
                Create Process
              </button>
            </div> */}
            <ProcessPlanner bion={bion} />
          </>
        ) : (
          <>
            <header>
              <div>
                <div className={styles["header_row"]}>
                  <h1 className={styles["title"]}>
                    <img src={BionImg} alt="" />
                    {bion.bionName}
                  </h1>
                  <button
                    onClick={openCalibration}
                    className={
                      isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)
                        ? styles["need-calibration"]
                        : styles["calibrated"]
                    }
                  >
                    <div>
                      <span className={styles["button_title"]}>
                        Sensor Calibration
                      </span>
                      <span className={styles["date"]}>
                        Last time:{" "}
                        {bion?.lastPhCalibrationDate
                          ? new Date(bion.lastPhCalibrationDate).toDateString()
                          : "Never"}
                      </span>
                      {/* <span className={styles["date"]}>
                        {isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)
                          ? "Calibrate Now"
                          : ""}
                      </span> */}
                    </div>

                    <CalibrationIcon />
                  </button>
                </div>
              </div>
              <p className={styles["subtitle"]}>
                Welcome to the BION Process Creation page, where you have the
                power to design and tailor the operational processes for your
                specific bioreactor. Choose from three distinct pathways: craft
                a custom process to meet your unique requirements, replicate an
                existing one for efficiency, or dive into the innovative BIONIC
                mode for cutting-edge configurations. This intuitive interface
                empowers you to define the parameters that drive your
                bioreactor's performance. Whether you're a precision enthusiast
                or prefer the convenience of proven methods, this page puts the
                control of bioprocessing in your hands, ensuring that your BION
                operates in harmony with your vision.
              </p>
            </header>

            <div>
              <ul className={`${!bion.isLidSet ? styles["hide"] : ""}`}>
                <li>
                  <button
                    onClick={toggleCreation}
                    disabled={isDateMoreThan30DaysAgo(
                      bion.lastPhCalibrationDate
                    )}
                  >
                    <span>
                      <CreationIcon />
                    </span>
                    <span>
                      Unleash your creativity! Design a bespoke process tailored
                      to your specific needs. Define parameters, set conditions,
                      and sculpt a unique path for your BION.
                    </span>
                    <span>Creation</span>
                  </button>
                </li>
                <li>
                  <Link
                    to={
                      isDateMoreThan30DaysAgo(bion.lastPhCalibrationDate)
                        ? "#"
                        : `/files?bionId=${bion.bionId}`
                    }
                  >
                    <span>
                      <ReplicationIcon />
                    </span>
                    <span>
                      Efficiency at your fingertips! Replicate a proven process
                      with a click. Clone and adapt successful configurations
                      for a seamless and reliable operation.
                    </span>
                    <span>Replication</span>
                  </Link>
                </li>
                <li>
                  <button>
                    <span>
                      <BionicIcon />
                    </span>
                    <span>
                      Experience innovation! Step into BIONIC mode to explore
                      cutting-edge configurations. Embrace automated
                      intelligence and let your BION adapt dynamically to
                      optimize performance.
                    </span>
                    <span>Bionic</span>
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default CreateProcess;
