import { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Select from "react-select";

import { IoIosArrowDown as ExpandIcon } from "react-icons/io";
import { VscServerProcess as BioprocessDefinitionIcon } from "react-icons/vsc";
import { BsHourglassSplit as InProgressIcon } from "react-icons/bs";
import { FaCheckCircle as CompletedIcon } from "react-icons/fa";

import classNames from "classnames/bind";
import styles from "./DefineBioprocess.module.css";
import commonStyles from "../CommonStyles.module.css";

const getOptions = (options, name) => {
  return options[name];
};

const cx = classNames.bind({ ...styles, ...commonStyles });

const DefineBioprocess = ({
  isExpanded,
  bioprocessDefinitionPrompt,
  options,
}) => {
  // console.log("hi", bioprocessDefinitionPrompt);
  const [selectedValues, setSelectedValues] = useState({});

  // Count the number of placeholders using a one-liner
  const numberOfPlaceholders = (
    bioprocessDefinitionPrompt.match(/\{[^{}]+\}/g) || []
  ).length;

  const allValuesPresent =
    numberOfPlaceholders === Object.keys(selectedValues).length &&
    Object.values(selectedValues).every(Boolean);

  const selectChangeHandler = (name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const renderPrompt = (str) => {
    const parts = str.split(/({[^{}]+})/);
    return parts.map((part, index) => {
      if (!part.match(/{([^{}]+)}/)) return <span key={index}>{part}</span>;

      const placeholder = part.replace(/[{}]/g, "");
      const [type, placeholderName] = placeholder.split(":");
      switch (type) {
        case "select":
          return (
            <Select
              key={index}
              options={getOptions(options, placeholderName)}
              classNames={{
                container: () => cx("select__container"),
                control: () => cx(["select__control"]),
                indicatorSeparator: () => cx("select__seperator"),
                indicatorsContainer: () => cx(["select__indicator-container"]),
                input: () => cx("select__input"),
                singleValue: () => cx("select__single-value"),
                valueContainer: () => cx("select__value-container"),
                menu: () => cx("select__menu"),
                menuList: () => cx("select__menu-list"),
                option: ({ isFocused, isSelected }) => {
                  return cx("select__option", {
                    "select__option-is-focused": isFocused,
                    "select__option-is-selected": isSelected,
                  });
                },
              }}
              onChange={({ value }) =>
                selectChangeHandler(placeholderName, value)
              }
            />
          );
        case "input":
          return (
            <input
              key={index}
              // value={plan[placeholderName]}
              // onChange={(event) => {
              //   selectedValuesChangeHandler(
              //     planIdx,
              //     placeholderName,
              //     event.target.value
              //   );
              // }}
            />
          );
      }
    });
  };

  return (
    <Accordion
      // expanded={isExpanded}
      className={cx("accordion")}
      disableGutters
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandIcon className={cx("expand-icon")} />}
        className={cx("accordionSummary")}
      >
        <div className={cx(["accordionSummary__container"])}>
          <BioprocessDefinitionIcon style={{ color: "", fontSize: "50px" }} />

          <div className={cx(["info__container"])}>
            <div className={cx(["info__container-row"])}>
              <h3>Bioprocess Definition</h3>
              <p className={cx("status-indicator", "completed")}>
                <CompletedIcon /> Completed
              </p>
            </div>
            <p>Define essential parameters to iniate your bioprocess</p>
          </div>

          <div className={cx("status__container")}>
            <p>All set !</p>
            <p>Modify if required</p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={cx("accordionDetails")}>
        <div>
          <div className={styles["prompt"]}>
            {renderPrompt(bioprocessDefinitionPrompt)}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default DefineBioprocess;
