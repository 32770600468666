import React, { useState, useEffect } from "react";
import { Button } from "../components/UI/Shadcn/Button";
import { Input } from "../components/UI/Shadcn/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/UI/Shadcn/Select";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/UI/Shadcn/Card";
import { Skeleton } from "../components/UI/Shadcn/Skeleton";
import { AlertCircle } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../components/UI/Shadcn/Alert";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/UI/Shadcn/Accordion";

const PruebasMartin = () => {
  const [prompt, setPrompt] = useState("");
  const [placeholders, setPlaceholders] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({});

  const accessToken =
    "eyJraWQiOiIra3NGWkVCemQ5QjBQajhcL295NW5nanhid3RYa3Q2MXNBZkdORUJFTm5Ubz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwNjliZjMwZC0yY2I2LTQ4ZjYtYTFmOC0yZmE5N2U3YmFiMjAiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9zYjdzdk1aWTkiLCJjbGllbnRfaWQiOiIxZDZpM2JsZTZjcG5vN2Nrcjl1aW4zdDc1OSIsIm9yaWdpbl9qdGkiOiI0ZDM2Mzc1Mi00NTExLTQ0MTItYTI3ZC0wNDRlMmU5OWMwY2EiLCJldmVudF9pZCI6IjY2NWIzMDc5LTYyZGUtNDU4ZC1iYTZkLTY5YzJhYmU3NDEyOSIsInVzZXJfaWQiOiI2MTQxNzFkMy1lOWU2LTQ1MTItOTRjMy0yODNmNWQ5MzViOWUiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNzI2MTI1OTM2LCJleHAiOjE3MjYxMjk1MzYsImlhdCI6MTcyNjEyNTkzNiwianRpIjoiMzdkZTM1YjAtNGM0NC00OGYzLTg0MzItOWMzZmU4YTM4MmExIiwidXNlcm5hbWUiOiJtYXJ0aW5pcnUifQ.LG_NG5ntu22G5KKIcJXEmckOzYI6D11dEeUadZAMVVU-gDfzFV0m8RCYMUArdDxdrCHxViuX6U1vuCYDpzqMDCxpMAfLBTRSnl2-HsTE9bJkETjiRH_QfJz7UAHAUNd4xj0U_hGXJENrKleZAW2qNbnLl503TJVM-pHgKPkQlsWkofE9pOIi8w4Qmu3uQDILQ43NIpMDAWmjYKbdaKscLKVGwC3uBd2uHqaQ1YnKHp5WcJZWwg4c3iRyyTmxIoQtvq2PNaCU2wB5sl3DVFsyMRCb1wLq38M50qhxPmCYphPluErTSuiS-plxWI7M7AuGdLOGdeFMZ2MnPPuTR8e4sw";

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [promptResponse, placeholdersResponse] = await Promise.all([
        fetch(
          "http://localhost:8080/cultzyme-api/v1/administrations/MAXI_PROD_PROMPT/base-prompt",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        ),
        fetch(
          "http://localhost:8080/cultzyme-api/v1/administrations/maximize-productions/placeholders",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        ),
      ]);

      if (!promptResponse.ok || !placeholdersResponse.ok) {
        throw new Error("Failed to fetch data");
      }

      const promptData = await promptResponse.json();
      const placeholdersData = await placeholdersResponse.json();

      setPrompt(promptData.basePrompt);
      setPlaceholders(placeholdersData);
    } catch (err) {
      setError("An error occurred while fetching the data");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (key, value) => {
    setInputValues((prev) => ({ ...prev, [key]: value }));
  };

  const renderPromptWithInputs = () => {
    if (!prompt) return null;

    const parts = prompt.split(/(\{[^}]+\})/g);
    return parts.map((part, index) => {
      if (part.startsWith("{") && part.endsWith("}")) {
        const key = part.slice(1, -1);
        if (placeholders[key]) {
          return (
            <Select
              key={index}
              onValueChange={(value) => handleInputChange(key, value)}
              value={inputValues[key] || ""}
            >
              <SelectTrigger className="w-[180px] inline-block">
                <SelectValue placeholder={`Select ${key}`} />
              </SelectTrigger>
              <SelectContent>
                {placeholders[key].map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.description}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          );
        } else {
          return (
            <Input
              key={index}
              placeholder={key}
              value={inputValues[key] || ""}
              onChange={(e) => handleInputChange(key, e.target.value)}
              className="w-[180px] inline-block"
            />
          );
        }
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className="container mx-auto p-4">
      <Card className="w-full max-w-3xl mx-auto mb-8">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Pruebas Con Prompt Engine
          </CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="w-full h-40" />
          ) : error ? (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          ) : (
            <>
              <h3 className="text-xl font-semibold mb-2">Prompt con inputs:</h3>
              <div className="bg-gray-100 p-4 rounded-md mb-6">
                {renderPromptWithInputs()}
              </div>
            </>
          )}
          <Button onClick={fetchData} className="mt-4" disabled={isLoading}>
            {isLoading ? "Loading..." : "Refresh Data"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default PruebasMartin;
