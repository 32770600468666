import { useEffect, useRef, useState } from "react";

import DigitInput from "../DigitInput/DigitInput";

import { useLazyRequestVerificationCodeQuery } from "../../../features/auth/authApiSlice";
import useTimer from "../../../hooks/use-timer";

import styles from "./Step3.module.css";
import { Card, CardContent, CardHeader, CardTitle } from "../../UI/Shadcn/Card";
import { Button } from "../../UI/Shadcn/Button";
import { Input } from "../../UI/Shadcn/Input";

// Constants
const DIGIT_INPUTS_NUMBER = 6;
const RESEND_TIMER_DURATION = 60; // 60 seconds (1 minute)

const Step3 = ({ userName, message, onSubmit }) => {
  const [verificationCode, setVerificationCode] = useState(
    Array(DIGIT_INPUTS_NUMBER).fill("")
  );
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const inputRefs = useRef([]);

  const { secondsLeft, restartTimer } = useTimer(RESEND_TIMER_DURATION, {
    startAutomatically: true,
    direction: "desc",
  });

  const [requestVerificationCodeReq] = useLazyRequestVerificationCodeQuery();

  useEffect(() => {
    if (allInputsFilled) {
      onSubmit(verificationCode.join(""));
    }
  }, [allInputsFilled, onSubmit, verificationCode]);

  const handleInputChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);

    if (value && index < DIGIT_INPUTS_NUMBER - 1) {
      inputRefs.current[index + 1].focus();
    }

    setAllInputsFilled(newVerificationCode.every((digit) => digit !== ""));
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !verificationCode[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendClick = () => {
    if (secondsLeft > 0) return;
    requestVerificationCodeReq(userName);
    restartTimer();
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          Verify Your Account
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-4 text-sm text-gray-600">
          The final step! We've sent a verification code to your email address.
          Enter it below to complete your account setup.
        </p>
        <div className="flex justify-between items-center mb-4">
          <span className="text-sm font-medium">Enter code</span>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleResendClick}
            disabled={secondsLeft > 0}
          >
            {secondsLeft > 0 ? `Resend in ${secondsLeft}s` : "Resend code"}
          </Button>
        </div>
        <div className="flex gap-2 mb-4">
          {verificationCode.map((digit, index) => (
            <Input
              key={index}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={1}
              className="w-12 h-12 text-center text-lg"
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        {message && <p className="text-sm text-gray-600">{message}</p>}
      </CardContent>
    </Card>
  );
};

export default Step3;
