import React, { useState } from "react";
import { Edit, User, Mail, Shield } from "lucide-react";
import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from "../../features/user/userApiSlice";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/UI/Shadcn/Card";
import { Button } from "../../components/UI/Shadcn/Button";
import { Input } from "../../components/UI/Shadcn/Input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/UI/Shadcn/Dialog";
import { Label } from "../../components/UI/Shadcn/Label";
import { toast } from "../../hooks/use-toast";
import { Skeleton } from "../../components/UI/Shadcn/Skeleton";

const UpdateAliasDialog = ({ isOpen, onClose, currentAlias }) => {
  const [alias, setAlias] = useState(currentAlias);
  const [updateUserData] = useUpdateUserDataMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserData({ alias });
      toast({
        title: "Alias updated",
        description: "Your alias has been successfully updated.",
      });
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update alias. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Update Alias</DialogTitle>
          <DialogDescription>
            Enter your new alias below. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="alias" className="text-right">
                Alias
              </Label>
              <Input
                id="alias"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                className="col-span-3"
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Save changes</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const UserInfoItem = ({ icon: Icon, label, value, onEdit }) => (
  <div className="flex items-center justify-between p-4 rounded-lg bg-card hover:bg-accent transition-colors duration-200">
    <div className="flex items-center space-x-4">
      <Icon className="h-5 w-5 text-muted-foreground" />
      <div>
        <p className="text-sm font-medium leading-none">{label}</p>
        <p className="text-sm text-muted-foreground">{value}</p>
      </div>
    </div>
    {onEdit && (
      <Button
        variant="ghost"
        size="sm"
        onClick={onEdit}
        className="hover:bg-primary hover:text-primary-foreground transition-colors duration-200"
      >
        <Edit className="h-4 w-4" />
        <span className="sr-only">Edit {label}</span>
      </Button>
    )}
  </div>
);

const Settings = () => {
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const { data: userData, isLoading, error } = useGetUserDataQuery();

  if (isLoading) {
    return (
      <div className="container mx-auto p-6 space-y-8">
        <Skeleton className="h-12 w-3/4" />
        <Skeleton className="h-6 w-1/2" />
        <Card>
          <CardHeader>
            <Skeleton className="h-8 w-1/4" />
            <Skeleton className="h-4 w-1/2" />
          </CardHeader>
          <CardContent className="space-y-4">
            {[1, 2, 3].map((i) => (
              <Skeleton key={i} className="h-16 w-full" />
            ))}
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-6 text-center">
        <Card className="p-6 bg-destructive/10 border-destructive">
          <CardTitle className="text-destructive mb-2">Error</CardTitle>
          <CardDescription>
            An error occurred while loading user data. Please try again later.
          </CardDescription>
        </Card>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 space-y-8 bg-background">
      <header>
        <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-4">
          Settings
        </h1>
        <p className="text-xl text-muted-foreground">
          Manage your account settings and preferences.
        </p>
      </header>

      <Card className="shadow-lg bg-card">
        <CardHeader>
          <CardTitle>User Information</CardTitle>
          <CardDescription>
            View and edit your personal information.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <UserInfoItem
            icon={User}
            label="Username"
            value={userData?.userName}
          />
          <UserInfoItem icon={Mail} label="Email" value={userData?.email} />
          <UserInfoItem
            icon={Shield}
            label="Alias"
            value={userData?.alias}
            onEdit={() => setUpdateIsOpen(true)}
          />
        </CardContent>
      </Card>

      <UpdateAliasDialog
        isOpen={updateIsOpen}
        onClose={() => setUpdateIsOpen(false)}
        currentAlias={userData?.alias}
      />
    </div>
  );
};

export default Settings;
