import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { LogIn, Eye, EyeOff } from "lucide-react";

import { useSigninUserMutation } from "../../features/auth/authApiSlice";
import { setCredentials } from "../../features/auth/authSlice";
import loginSchema from "../../validations/login";

import { Button } from "../../components/UI/Shadcn/Button";
import { Input } from "../../components/UI/Shadcn/Input";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/UI/Shadcn/Card";

import logoImg from "../../assets/images/logo.png";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const referrerUrl = state?.from
    ? `${state.from.pathname}${state.from.search}`
    : "/";

  const [signinUserReq, { isLoading: signinUserReqIsLoading }] =
    useSigninUserMutation();

  const loginSubmitHandler = async (data) => {
    try {
      const signinUserResData = await signinUserReq({
        usernameOrEmail: data.usernameOrEmail,
        password: data.password,
      }).unwrap();

      dispatch(setCredentials(signinUserResData));
      navigate(referrerUrl, { replace: true });
    } catch (error) {
      console.log("Error during login:", error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-background">
      <div className="w-full md:w-1/2 relative overflow-hidden bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 md:min-h-screen h-64">
        <div className="absolute inset-0 flex items-center justify-center">
          <img className="w-48" src={logoImg} alt="Cultzyme" />
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center p-4 sm:p-8">
        <Card className="w-full max-w-md shadow-lg bg-card">
          <CardHeader>
            <div className="flex items-center mb-4">
              <CardTitle className="text-2xl font-bold ">Log In</CardTitle>
            </div>
            <p className="text-muted-foreground">
              Welcome back! Please enter your details.
            </p>
          </CardHeader>
          <CardContent>
            <form id="login-form" onSubmit={handleSubmit(loginSubmitHandler)}>
              <div className="space-y-4">
                <div>
                  <Input
                    id="usernameOrEmail"
                    placeholder="Email or Username"
                    {...register("usernameOrEmail")}
                    error={errors.usernameOrEmail?.message}
                    className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                    icon={
                      <LogIn className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    }
                  />
                  {errors.usernameOrEmail && (
                    <p className="text-sm text-destructive mt-1">
                      {errors.usernameOrEmail.message}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    {...register("password")}
                    error={errors.password?.message}
                    className="pl-10 pr-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                    icon={
                      <LogIn className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    }
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    {showPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </button>
                  {errors.password && (
                    <p className="text-sm text-destructive mt-1">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </CardContent>
          <CardFooter className="flex flex-col items-stretch gap-4">
            <Button
              form="login-form"
              type="submit"
              disabled={!isValid || signinUserReqIsLoading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white transition duration-200 ease-in-out"
            >
              {signinUserReqIsLoading ? "Loading..." : "Log in"}
              <LogIn className="ml-2 h-4 w-4" />
            </Button>
            <div className="flex justify-between text-sm">
              <Link to="/signup" className="text-blue-600 hover:underline">
                Sign up
              </Link>
              <Link
                to="/forget-password"
                className="text-blue-600 hover:underline"
              >
                Forgot password?
              </Link>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default Login;
