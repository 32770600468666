import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../../components/UI/Shadcn/Card";
import { Badge } from "../../components/UI/Shadcn/Badge";
import { Button } from "../../components/UI/Shadcn/Button";
import { RiTeamFill } from "react-icons/ri";
import { BsPlugin } from "react-icons/bs";
import { TbStairs } from "react-icons/tb";
import { motion } from "framer-motion";

import bionDashboardImg from "../../assets/images/bion-dashboard.png";
import aiImg from "../../assets/images/ai.png";
import storeImg from "../../assets/images/store.png";

const appData = [
  {
    title: "BIONS Dashboard",
    creator: "Cultzyme",
    description: "Access and configure your BIONS while running a bioprocess.",
    link: "/bions",
    badgeText: "BIONS",
    badgeColor: "bg-blue-500",
    icon: (
      <img
        width={80}
        src={bionDashboardImg}
        alt="BIONS Dashboard"
        className="mx-auto"
      />
    ),
  },
  {
    title: "In Silico",
    creator: "Cultzyme",
    description:
      "Train your AI for bioprocess specific tasks by providing data.",
    link: "/cultzyme-ai",
    badgeText: "AI - Quantum",
    badgeColor: "bg-pink-500",
    icon: <img width={90} src={aiImg} alt="AI" className="mx-auto" />,
  },
  {
    title: "Community",
    creator: "Cultzyme",
    description:
      "Connect with other experts, share knowledge, and collaborate.",
    link: "/home",
    badgeText: "Community",
    badgeColor: "bg-purple-500",
    icon: <RiTeamFill size={60} className="mx-auto text-purple-500" />,
  },
  {
    title: "CULTZYME Store",
    creator: "Cultzyme",
    description:
      "Explore and purchase enzymes tailored for your bioprocess needs.",
    link: "/home",
    badgeText: "Store",
    badgeColor: "bg-orange-500",
    icon: <img width={90} src={storeImg} alt="Store" className="mx-auto" />,
  },
  {
    title: "Plugin Store",
    creator: "Cultzyme",
    description:
      "Find and install plugins to extend the capabilities of your applications.",
    link: "/home",
    badgeText: "Plugins",
    badgeColor: "bg-cyan-500",
    icon: <BsPlugin size={60} className="mx-auto text-cyan-500" />,
  },
  {
    title: "Scale Up",
    creator: "Cultzyme",
    description:
      "Tools and guidance for scaling your bioprocesses efficiently.",
    link: "/home",
    badgeText: "Scale Up",
    badgeColor: "bg-green-500",
    icon: <TbStairs size={60} className="mx-auto text-green-500" />,
  },
];

const AppCard = ({ app }) => (
  <motion.div
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
    className="h-full"
  >
    <Card className="flex flex-col h-full transition-all bg-card duration-300 hover:shadow-xl hover:border-primary">
      <CardHeader>
        <div className="flex justify-between items-start">
          <CardTitle className="text-xl font-bold">{app.title}</CardTitle>
          <Badge className={`${app.badgeColor} text-white`}>
            {app.badgeText}
          </Badge>
        </div>
        <CardDescription>By {app.creator}</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="mb-4">{app.icon}</div>
        <p className="text-muted-foreground">{app.description}</p>
      </CardContent>
      <CardFooter className="mt-auto">
        <Button
          asChild
          variant="outline"
          className="w-full bg-primary hover:bg-primary/70 text-primary-foreground transition-colors duration-200"
        >
          <Link to={app.link}> {app.title}</Link>
        </Button>
      </CardFooter>
    </Card>
  </motion.div>
);

const Home = () => {
  return (
    <div className="container mx-auto p-6 space-y-8">
      <header className="text-left space-y-4">
        <h1 className="text-4xl font-bold tracking-tight lg:text-5xl">
          Cultzyme Dashboard
        </h1>
        <p className="text-xl text-muted-foreground max-w-2xl">
          Welcome to your central hub for bioprocess management and innovation.
          Explore our suite of tools designed to enhance your workflow.
        </p>
      </header>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {appData.map((app) => (
          <AppCard key={app.title} app={app} />
        ))}
      </div>
    </div>
  );
};

export default Home;
