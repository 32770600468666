import { useEffect } from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

import styles from "./ModalWrapper.module.css";

// The DOM element where modals will be rendered
const modalRoot = document.getElementById("modal-root");

const ModalWrapper = ({
  children,
  onClose,
  customAnimationVariants,
  onAnimationComplete,
}) => {
  useEffect(() => {
    const closeModalHandler = () => {
      onClose();
    };

    const escapeKeyHandler = (event) => {
      if (event.key === "Escape") closeModalHandler();
    };

    window.addEventListener("keydown", escapeKeyHandler);
    document.body.style.overflow = "hidden";

    return () => {
      window.removeEventListener("keydown", escapeKeyHandler);
      document.body.style.overflow = "auto";
    };
  }, [onClose]);

  const defaultAnimationVariants = {
    initial: { opacity: 0, x: "-50%", y: "calc(-50% - 30px)" },
    visible: { opacity: 1, x: "-50%", y: "-50%" },
    exit: { opacity: 0, x: "-50%", y: "calc(-50% + 30px)" },
  };

  const ModalContent = (
    <motion.dialog
      variants={customAnimationVariants || defaultAnimationVariants}
      initial="initial"
      animate="visible"
      exit="exit"
      onAnimationComplete={onAnimationComplete}
      open
      className={styles["modal-wrapper"]}
    >
      {children}
    </motion.dialog>
  );

  return createPortal(
    <>
      <div className={styles["backdrop"]} onClick={onClose}></div>
      {ModalContent}
    </>,
    modalRoot
  );
};

export default ModalWrapper;
