import {
  useUpdateCalibrationMutation,
  useGetCalibrationStatusQuery,
} from "../../../features/bion/bionApiSlice";

import { useStartSensorCalibrationMutation } from "../../../features/sensor/sensorApiSlice";

import ModalWrapper from "../../Wrappers/ModalWrapper/ModalWrapper";
import { motion } from "framer-motion";

import styles from "./CalibrateModal.module.css";
import { useEffect } from "react";
import LoadingText from "../../UI/LoadingText/LoadingText";

const CalibrateModal = ({ onClose, bion, createProcess }) => {
  const [updateCalibrationReq, { isLoading: updateCalibrationReqIsLoading }] =
    useUpdateCalibrationMutation();

  const [
    startSensorCalibrationReq,
    {
      isLoading: startSensorCalibrationReqIsLoading,
      isSuccess: startSensorCalibrationReqIsSuccess,
    },
  ] = useStartSensorCalibrationMutation();

  const { data: calibrationData, refetch } = useGetCalibrationStatusQuery(
    bion.bionId,
    { refetchOnMountOrArgChange: true }
  );

  const updateCalibrationHandler = async (active) => {
    await updateCalibrationReq({
      bionId: bion.bionId,
      calibrationActive: active,
      sensorType: "PH",
    });

    if (active) refetch();

    if (!active) {
      onClose();
    }
  };

  useEffect(() => {
    if (
      calibrationData?.[0]?.calibrationFinished &&
      bion.bionMode === "CALIBRATION_ACTIVE"
    ) {
      // createProcess();
      onClose(false);
    }
  }, [calibrationData]);

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["calibration-modal"]}>
        <h4>Calibrate pH Sensor</h4>
        <p>
          Before you start creating a new process, we recommend calibrating the
          pH sensor for accurate readings. Calibration ensures precise control
          over the bioreactor environment, resulting in optimal conditions for
          your experiments. Would you like to calibrate the pH sensor now
        </p>
        {bion?.bionMode === "CALIBRATION_ACTIVE" ? (
          <>
            <div style={{ textAlign: "center" }}>
              <h1 style={{ marginBottom: "12px" }}>
                Calibration Step: {calibrationData?.[0]?.step}
              </h1>
              <p>Step Instruction: {calibrationData?.[0]?.message}</p>
              <button
                className={styles["cancel-calibration"]}
                // onClick={updateCalibrationHandler.bind(null, false)}
                onClick={onClose}
              >
                Cancel Calibration
              </button>
              <button
                className={styles["start-calibration"]}
                // onClick={updateCalibrationHandler.bind(null, false)}
                onClick={() => {
                  startSensorCalibrationReq({
                    bionId: bion.bionId,
                    calibrationActive: true,
                    sensorType: "PH",
                    step: calibrationData?.[0]?.step,
                  });
                }}
              >
                {startSensorCalibrationReqIsLoading ? (
                  <LoadingText />
                ) : (
                  "Start Calibration"
                )}
              </button>
            </div>
          </>
        ) : (
          <div className={styles["action-btns"]}>
            {/* <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 1 }}
              transition={{ type: "spring", duration: 0.5, bounce: 0.5 }}
              onClick={createProcess}
            >
              Calibrate Later
            </motion.button> */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 1 }}
              onClick={updateCalibrationHandler.bind(null, true)}
            >
              {updateCalibrationReqIsLoading ? (
                <LoadingText />
              ) : (
                "Calibrate Now"
              )}
            </motion.button>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default CalibrateModal;
