import { useState, cloneElement } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { IoIosArrowDown as ExpandIcon } from "react-icons/io";
import { VscServerProcess as BioprocessDefinitionIcon } from "react-icons/vsc";
import { BsHourglassSplit as InProgressIcon } from "react-icons/bs";
import { FaCheckCircle as CompletedIcon } from "react-icons/fa";
import { MdAutoAwesome as ControlsIcon } from "react-icons/md";

import MenuImg from "../../../../../assets/images/PlannerImages/menu.png";

import classNames from "classnames/bind";
import styles from "./ControlPlanning.module.css";
import commonStyles from "../CommonStyles.module.css";
import { useGetPlannerPromptsQuery } from "../../../../../features/administration/administrationApiSlice";

const cx = classNames.bind({ ...styles, ...commonStyles });

const ControlPlanning = ({
  isExpanded,
  plannerData,
  setPlannerData,
  controls,
}) => {
  const [controlSelected, setControlSelected] = useState();

  const selectControlHandler = (controlName) => {
    setControlSelected(controlName);
  };

  const getComponentProps = () => {
    const compData = controls.find((part) => part.name === controlSelected);
    return {
      name: compData.name,
      options: compData.fetchedPlaceholders,
      prompts: compData.fetchedPrompts,
      plannerData: plannerData,
      setPlannerData: setPlannerData,
    };
  };

  return (
    <Accordion
      //   expanded={isExpanded}
      className={cx("accordion")}
      disableGutters
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandIcon className={cx("expand-icon")} />}
        className={cx("accordionSummary")}
      >
        <div className={cx("accordionSummary__container")}>
          <ControlsIcon style={{ color: "", fontSize: "50px" }} />

          <div className={cx("info__container")}>
            <div>
              <h3>Controls Planning</h3>
            </div>
            <p>Chart precise controls for optimal bioprocess conditions</p>
          </div>

          <div className={cx("status__container")}>
            <p>Not started</p>
            <p>Fill the details</p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={cx("accordionDetails")}
        style={{ minHeight: "70vh" }}
      >
        {controlSelected ? (
          <div>
            <div className={styles["container"]}>
              {/* Vertical Steps */}
              <div className={cx("vertical-steps")}>
                <button
                  className={cx("step")}
                  onClick={selectControlHandler.bind(null, null)}
                >
                  <span className={cx("circle")}>
                    <img src={MenuImg} />
                  </span>
                  {/* <span className={cx("name")}>{control.name}</span> */}
                </button>
                {controls.map((control) => (
                  <button
                    key={control.name}
                    className={cx("step", {
                      active: control.name === controlSelected,
                    })}
                    onClick={selectControlHandler.bind(null, control.name)}
                  >
                    <span className={cx("circle")}>
                      <img src={control.img} />
                    </span>
                    {/* <span className={cx("name")}>{control.name}</span> */}
                  </button>
                ))}
              </div>

              <div className={cx("control__container")}>
                {/* {controlSelected && */}
                {/* CONTROLS.find((control) => control.name === controlSelected) */}
                {/* .component} */}
                {controlSelected &&
                  cloneElement(
                    controls.find((control) => control.name === controlSelected)
                      .component,
                    getComponentProps()
                  )}
              </div>
            </div>
          </div>
        ) : (
          <div className={cx("box-container")}>
            {controls.map((control) => (
              <button onClick={setControlSelected.bind(null, control.name)}>
                <div className={cx("control-planning-img-container")}>
                  <img
                    src={control.img}
                    className={cx("control-planning-img")}
                    alt="description"
                  />
                </div>
                <div>{control.name}</div>
              </button>
            ))}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ControlPlanning;
