import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "./features/auth/authSlice";
import { apiSlice } from "./app/api/apiSlice";

import RequireAuth from "./features/auth/RequireAuth";
import AskQuestion from "./components/AI/AskQuestion/AskQuestion";
import MaximizeProduction from "./components/AI/MaximizeProduction/MaximizeProduction";

import AppWrapper from "./components/Wrappers/AppWrapper/AppWrapper";
import BionsManagementAppWrapper from "./components/Wrappers/BionsManagementAppWrapper/BionsManagementAppWrapper";
import Login from "./pages/Login/Login";
import Bions from "./pages/BionsManagementApp/Bions/Bions";
import Bion from "./pages/BionsManagementApp/Bion/Bion";
import Signup from "./pages/Signup/Signup";
import Files from "./pages/BionsManagementApp/Processes/Files";
import Settings from "./pages/Settings/Settings";
import RegistrationRequest from "./pages/RegistrationRequest/RegistrationRequest";
import Process from "./pages/Process/Process";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Home from "./pages/Home/Home";
import AI from "./pages/AI/AI";
import PruebasMartin from "./components/PruebasMartin";
import ChatContainer from "./components/AI/ChatContainer/ChatContainer";
import FullScreenChatbot from "./components/AI/FullScreenChatbot";
import ChatbotContainer from "./components/ChatbotContainer";

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const [tokenExisted, setTokenExisted] = useState(!!token);
  // console.log(token, "✅");

  useEffect(() => {
    if (!token && tokenExisted) {
      dispatch(apiSlice.util.resetApiState());
    }
    setTokenExisted(!!token);
  }, [token, dispatch, tokenExisted]);

  return (
    <Routes>
      <Route path="/signin" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route element={<RequireAuth />}>
        <Route element={<AppWrapper />}>
          <Route path="/home" element={<Home />} />
          <Route path="/bions" element={<Bions />} />
          <Route path="/bions/:bionId" element={<Bion />} />
          <Route path="/files" element={<Files />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/processes/:processId" element={<Process />} />
          <Route
            path="auth/accounts/:action"
            element={<RegistrationRequest />}
          />

          <Route
            path="/bions-management-app"
            element={<BionsManagementAppWrapper />}
          >
            <Route index element={<Navigate to="bions" replace />} />
            <Route path="bions" element={<Bions />} />
            <Route path="bions/:bionId" element={<Bion />} />
          </Route>

          <Route path="/cultzyme-ai" element={<AI />} />
          <Route path="/cultzyme-ai/:chatId" element={<AI />} />
          <Route path="/cultzyme-ai/ask" element={<AskQuestion />} />
          <Route
            path="/cultzyme-ai/maximize"
            element={<MaximizeProduction />}
          />

          <Route path="/pruebas" element={<PruebasMartin />} />
          <Route path="/prueba-chatbot" element={<ChatContainer />} />
          <Route path="/chatbot" element={<ChatbotContainer />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default App;
