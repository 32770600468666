import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-hot-toast";
import { setCredentials, logOut } from "../../features/auth/authSlice";
import { getLocalStorageItems } from "../../utils/localStorageUtils";

// Función base para hacer peticiones con la API
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL + "/cultzyme-api/v1", // Uso de la variable de entorno
  prepareHeaders: (headers, { getState }) => {
    // Obtenemos el token de acceso desde el estado de Redux
    const accessToken = getState().auth.token;

    // Si tenemos el token, lo añadimos al header de autorización
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

// Función que maneja el refresco del token y reintentos en caso de error 401
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Realizamos la petición API
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data?.message) toast.error(result.error.data.message);
  if (result?.data?.message) toast.success(result.data.message);

  // Si obtenemos un error 401 (no autorizado), intentamos refrescar el token
  if (result?.error?.status === 401) {
    // Obtenemos el refresh token desde el almacenamiento local
    const refreshToken = getLocalStorageItems("refreshToken");

    // Si tenemos refresh token, enviamos una solicitud para renovarlo
    const refreshResult = await baseQuery(
      {
        url: "/auth/refreshtoken",
        method: "POST",
        headers: { refreshtoken: refreshToken },
        body: { userName: api.getState().auth.user?.userName },
      },
      api,
      extraOptions
    );

    // Si la renovación es exitosa, actualizamos el token de acceso
    if (refreshResult?.data) {
      api.dispatch(setCredentials(refreshResult.data));

      // Reintentamos la petición original con el nuevo token de acceso
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Si falla la renovación, cerramos sesión
      api.dispatch(logOut());
    }
  }

  return result;
};

// Creación del slice de la API usando Redux Toolkit
export const apiSlice = createApi({
  reducerPath: "apiOne",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Process"],
  endpoints: (build) => ({}),
});
