import React, { useState, useEffect, useRef } from "react";
import { Button } from "./UI/Shadcn/Button";
import { Card, CardContent, CardHeader, CardTitle } from "./UI/Shadcn/Card";
import { Alert, AlertDescription, AlertTitle } from "./UI/Shadcn/Alert";
import { Input } from "./UI/Shadcn/Input";
import { ScrollArea } from "./UI/Shadcn/ScrollArea";
import { Avatar, AvatarFallback, AvatarImage } from "./UI/Shadcn/Avatar";
import { Slider } from "./UI/Shadcn/Slider";
import { Switch } from "./UI/Shadcn/Switch";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./UI/Shadcn/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./UI/Shadcn/Tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./UI/Shadcn/DropdownMenu";
import { Separator } from "./UI/Shadcn/Separator";
import { Label } from "./UI/Shadcn/Label";
import {
  Trash2,
  Send,
  Plus,
  MessageSquare,
  Settings,
  MoreHorizontal,
  Loader2,
  ChevronLeft,
  Bot,
  Menu,
  Download,
  Edit2,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import { apiSlice } from "../app/api/apiSlice";
import { marked } from "marked";

export default function ChatbotContainer() {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [temperature, setTemperature] = useState(0.5);
  const [usePublicData, setUsePublicData] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const accessToken = useSelector(selectCurrentToken);
  const [tokenExisted, setTokenExisted] = useState(!!accessToken);

  const quickReplies = [
    "Can you explain it better?",
    "What's next?",
    "Give me an example",
  ];

  useEffect(() => {
    if (!accessToken && tokenExisted) {
      dispatch(apiSlice.util.resetApiState());
    }
    setTokenExisted(!!accessToken);
  }, [accessToken, dispatch, tokenExisted]);

  useEffect(() => {
    getChats();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getChats = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch chats");
      const data = await response.json();
      setChats(
        data.sort((a, b) => new Date(b.lastActivity) - new Date(a.lastActivity))
      );
    } catch (err) {
      setError("Error fetching chats: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createChat = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title: "New Chat" }),
        }
      );
      if (!response.ok) throw new Error("Failed to create chat");

      const chatId = await response.text();
      const newChat = {
        chatId,
        startTime: new Date().toISOString(),
        lastActivity: new Date().toISOString(),
        preview: "New Chat",
      };
      setChats((prevChats) => [newChat, ...prevChats]);
      setSelectedChat(newChat);
      setMessages([]);
    } catch (err) {
      setError("Error creating chat: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChat = async (chatId, event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats/${chatId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to delete chat");

      setChats((prevChats) =>
        prevChats.filter((chat) => chat.chatId !== chatId)
      );
      if (selectedChat?.chatId === chatId) {
        setSelectedChat(null);
        setMessages([]);
      }
    } catch (err) {
      setError("Error deleting chat: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const selectChat = async (chat) => {
    setSelectedChat(chat);
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats/${chat.chatId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) throw new Error("Failed to load chat messages");
      const data = await response.json();
      setMessages(data.userAiMessages || []);
    } catch (err) {
      setError("Error loading chat messages: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const exportChat = () => {
    const chatData = JSON.stringify(messages, null, 2);
    const blob = new Blob([chatData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `chat_${selectedChat?.chatId}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const editMessage = (messageId) => {
    const messageToEdit = messages.find((msg) => msg.messageId === messageId);
    setInputMessage(messageToEdit.content);
    setEditingMessageId(messageId);
  };

  const sendMessage = async () => {
    if (!inputMessage.trim() || !selectedChat) return;

    try {
      setIsLoading(true);
      setIsTyping(true);

      const userMessage = {
        messageId: editingMessageId || Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "USER",
        content: inputMessage,
      };

      if (editingMessageId) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.messageId === editingMessageId ? userMessage : msg
          )
        );
        setEditingMessageId(null);
      } else {
        setMessages((prevMessages) => [...prevMessages, userMessage]);
      }

      setInputMessage("");

      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: inputMessage,
            chatId: selectedChat.chatId,
            temperature: temperature,
            withPublicData: usePublicData,
          }),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Failed to send message");
      }

      const reader = response.body.getReader();
      let aiResponse = "";
      const decoder = new TextDecoder();

      let tempAiMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "AI",
        content: "",
      };

      setMessages((prevMessages) => [...prevMessages, tempAiMessage]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        for (const line of lines) {
          if (line.startsWith("data:")) {
            aiResponse += line.slice(5);

            tempAiMessage.content = marked(aiResponse);

            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.messageId === tempAiMessage.messageId ? tempAiMessage : msg
              )
            );
          }
        }
      }

      tempAiMessage.content = marked(
        aiResponse || "Lo siento, no pude generar una respuesta."
      );
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.messageId === tempAiMessage.messageId ? tempAiMessage : msg
        )
      );

      const updatedChat = {
        ...selectedChat,
        preview: inputMessage,
        lastActivity: new Date().toISOString(),
      };
      setChats((prevChats) => {
        const filteredChats = prevChats.filter(
          (chat) => chat.chatId !== selectedChat.chatId
        );
        return [updatedChat, ...filteredChats];
      });
      setSelectedChat(updatedChat);
    } catch (err) {
      setError("Error sending message: " + err.message);
      const errorMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "SYSTEM",
        content: "Error al enviar el mensaje. Por favor, intenta de nuevo.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const clearError = () => {
    setError(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className="grid h-screen bg-background text-foreground overflow-hidden"
      style={{ gridTemplateColumns: isSidebarOpen ? "280px 1fr" : "0 1fr" }}
    >
      {/* Sidebar */}
      <div
        className={`bg-card border-r border-border flex flex-col h-full transition-all duration-300 ease-in-out overflow-hidden ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 flex flex-col space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-foreground">Chats</h2>
            <Button
              variant="ghost"
              size="icon"
              onClick={toggleSidebar}
              className="md:hidden text-muted-foreground hover:text-foreground"
            >
              <ChevronLeft className="h-5 w-5" />
            </Button>
          </div>
          <Button
            onClick={createChat}
            className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
            disabled={isLoading}
          >
            <Plus className="h-4 w-4 mr-2" />
            <span>New Chat</span>
          </Button>
          <Button
            onClick={exportChat}
            className="w-full bg-secondary text-secondary-foreground hover:bg-secondary/90"
          >
            <Download className="h-4 w-4 mr-2" />
            <span>Export Chat</span>
          </Button>
        </div>
        <ScrollArea className="flex-grow px-2">
          {chats.map((chat) => (
            <div
              key={chat.chatId}
              className="flex items-center justify-between mb-2 group"
            >
              <Button
                variant={
                  chat.chatId === selectedChat?.chatId ? "secondary" : "ghost"
                }
                className={`w-full justify-start text-left truncate ${
                  chat.chatId === selectedChat?.chatId
                    ? "bg-secondary text-secondary-foreground"
                    : "text-muted-foreground hover:text-foreground hover:bg-accent"
                }`}
                onClick={() => selectChat(chat)}
              >
                <MessageSquare className="mr-2 h-4 w-4 flex-shrink-0" />
                <span className="truncate">{chat.preview || "New Chat"}</span>
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity text-muted-foreground hover:text-foreground"
                  >
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={(e) => deleteChat(chat.chatId, e)}
                    className="text-destructive focus:text-destructive focus:bg-destructive/10"
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))}
        </ScrollArea>
      </div>

      {/* Main Chat Area */}
      <div className="flex flex-col h-full overflow-hidden">
        <CardHeader className="border-b border-border bg-card py-4">
          <div className="flex items-center">
            <Button
              variant="ghost"
              size="icon"
              onClick={toggleSidebar}
              className="mr-2 md:hidden text-muted-foreground hover:text-foreground"
            >
              <Menu className="h-5 w-5" />
            </Button>
            <CardTitle className="text-xl md:text-2xl font-bold flex items-center text-foreground">
              <Bot className="mr-2 h-5 w-5 md:h-6 md:w-6 text-primary" />
              Cultzyme AI Assistant
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="flex-grow overflow-hidden p-0 bg-background">
          <ScrollArea className="h-full px-4 py-6">
            {messages.map((message) => (
              <div
                key={message.messageId}
                className={`flex ${
                  message.senderType === "USER"
                    ? "justify-end"
                    : "justify-start"
                } mb-4`}
              >
                <div
                  className={`flex items-start max-w-[80%] sm:max-w-[70%] ${
                    message.senderType === "USER"
                      ? "flex-row-reverse"
                      : "flex-row"
                  }`}
                >
                  <Avatar className="w-8 h-8 mt-1">
                    <AvatarImage
                      src={
                        message.senderType === "USER"
                          ? "/user-avatar.png"
                          : "/bot-avatar.png"
                      }
                    />
                    <AvatarFallback>
                      {message.senderType === "USER" ? "U" : "AI"}
                    </AvatarFallback>
                  </Avatar>
                  <div
                    className={`mx-2 p-3 rounded-lg ${
                      message.senderType === "USER"
                        ? "bg-primary text-primary-foreground"
                        : "bg-secondary text-secondary-foreground"
                    }`}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: message.content }}
                    />
                    {message.senderType === "USER" && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => editMessage(message.messageId)}
                        className="mt-2 text-muted-foreground hover:text-foreground"
                      >
                        <Edit2 className="h-4 w-4 mr-1" />
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
            {isTyping && (
              <p className="text-muted-foreground italic">
                Ereky is writing...
              </p>
            )}
          </ScrollArea>
        </CardContent>
        <Card className="m-4 mt-0 border-t border-border bg-card">
          <CardContent className="p-4">
            <div className="flex flex-col space-y-4">
              {/* Quick Replies */}
              <div className="flex flex-wrap gap-2">
                {quickReplies.map((reply, index) => (
                  <Button
                    key={index}
                    onClick={() => setInputMessage(reply)}
                    variant="outline"
                    size="sm"
                    className="bg-secondary text-secondary-foreground hover:bg-secondary/90"
                  >
                    {reply}
                  </Button>
                ))}
              </div>
              <div className="flex items-center space-x-2">
                <div className="relative flex-grow">
                  <Input
                    ref={inputRef}
                    type="text"
                    placeholder="Type your message..."
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className="pr-20 focus:ring-2 focus:ring-ring focus:border-input rounded-full py-6 bg-background text-foreground placeholder-muted-foreground"
                    disabled={isLoading}
                  />
                  <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Dialog
                            open={isSettingsOpen}
                            onOpenChange={setIsSettingsOpen}
                          >
                            <DialogTrigger asChild>
                              <Button
                                variant="outline"
                                size="icon"
                                className="rounded-full bg-background text-muted-foreground hover:text-foreground hover:bg-accent"
                              >
                                <Settings className="h-4 w-4" />
                              </Button>
                            </DialogTrigger>
                            <DialogContent className="bg-background text-foreground">
                              <DialogHeader>
                                <DialogTitle>Chat Settings</DialogTitle>
                              </DialogHeader>
                              <div className="py-4">
                                <div className="mb-4">
                                  <Label className="text-sm font-medium mb-1 block text-foreground">
                                    Temperature: {temperature.toFixed(2)}
                                  </Label>
                                  <Slider
                                    value={[temperature]}
                                    onValueChange={(value) =>
                                      setTemperature(value[0])
                                    }
                                    max={1}
                                    step={0.01}
                                    className="mt-2"
                                  />
                                </div>
                                <Separator className="my-4 bg-border" />
                                <div className="flex items-center space-x-2">
                                  <Switch
                                    checked={usePublicData}
                                    onCheckedChange={setUsePublicData}
                                    id="public-data"
                                  />
                                  <Label
                                    htmlFor="public-data"
                                    className="text-sm font-medium text-foreground"
                                  >
                                    Use Public Data
                                  </Label>
                                </div>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </TooltipTrigger>
                        <TooltipContent className="bg-popover text-popover-foreground">
                          <p>Chat Settings</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <Button
                      onClick={sendMessage}
                      disabled={isLoading || !inputMessage.trim()}
                      size="icon"
                      className="bg-primary text-primary-foreground hover:bg-primary/90 rounded-full"
                    >
                      {isLoading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        <Send className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {error && (
        <Alert className="fixed bottom-4 right-4 w-full max-w-sm md:max-w-md bg-destructive text-destructive-foreground">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          <Button
            onClick={clearError}
            className="mt-2 bg-destructive-foreground text-destructive hover:bg-destructive-foreground/90"
          >
            Close
          </Button>
        </Alert>
      )}
    </div>
  );
}
