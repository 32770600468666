import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentToken,
  setCredentials,
} from "../../features/auth/authSlice";
import {
  useGetChatByIdQuery,
  useCreateChatMutation,
  useGetChatsQuery,
  useDeleteChatMutation,
} from "../../features/ai/aiApiSlice";
import { useGetUserDataQuery } from "../../features/user/userApiSlice";
import { getLocalStorageItems } from "../../utils/localStorageUtils";
import { Card, CardContent, CardHeader, CardTitle } from "../UI/Shadcn/Card";
import { Button } from "../UI/Shadcn/Button";
import { Input } from "../UI/Shadcn/Input";
import { ScrollArea } from "../UI/Shadcn/ScrollArea";
import { Avatar, AvatarFallback, AvatarImage } from "../UI/Shadcn/Avatar";
import { Slider } from "../UI/Shadcn/Slider";
import { Switch } from "../UI/Shadcn/Switch";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "../UI/Shadcn/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../UI/Shadcn/Tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/Shadcn/DropdownMenu";
import { Separator } from "../UI/Shadcn/Separator";
import { Label } from "../UI/Shadcn/Label";
import {
  Send,
  Trash2,
  Plus,
  MessageSquare,
  Settings,
  HelpCircle,
  MoreHorizontal,
  Edit2,
} from "lucide-react";

const FullScreenChatbot = () => {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [chatIsPrinting, setChatIsPrinting] = useState(false);
  const [input, setInput] = useState("");
  const [temperature, setTemperature] = useState(0.5);
  const [usePublicData, setUsePublicData] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const sessionToken = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((state) => state.auth.user.userName);
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const { data: userData } = useGetUserDataQuery();
  const {
    data: chatData,
    isLoading: chatLoading,
    error: chatError,
    refetch: refetchChat,
  } = useGetChatByIdQuery(chatId, { skip: !chatId });
  const {
    data: chats = [],
    isLoading: chatsLoading,
    refetch: refetchChats,
  } = useGetChatsQuery();
  const [createChat] = useCreateChatMutation();
  const [deleteChat] = useDeleteChatMutation();

  useEffect(() => {
    if (chatData) {
      setMessages(chatData?.userAiMessages || []);
    }
  }, [chatData]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    let incChatId = chatId || (await createNewChat());

    setChatIsPrinting(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { messageId: Date.now().toString(), content: input, senderType: "USER" },
    ]);
    setInput("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify({
            text: input,
            chatId: incChatId,
            temperature: temperature,
            withPublicData: usePublicData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.startsWith("data:")) {
            accumulatedResponse += line.slice(5);
            setMessages((prevMessages) => [
              ...prevMessages.slice(0, -1),
              {
                messageId: Date.now().toString(),
                content: accumulatedResponse,
                senderType: "AI",
              },
            ]);
          }
        }
      }

      refetchChat();
      refetchChats();
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          messageId: Date.now().toString(),
          content: "An error occurred while processing your message.",
          senderType: "AI",
          isError: true,
        },
      ]);
    } finally {
      setChatIsPrinting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const createNewChat = async () => {
    try {
      const newChat = await createChat().unwrap();
      navigate(`/cultzyme-ai/${newChat}`);
      return newChat;
    } catch (err) {
      console.error("Error creating new chat:", err);
    }
  };

  const deleteCurrentChat = async (id) => {
    try {
      await deleteChat(id).unwrap();
      if (chatId === id) navigate("/cultzyme-ai");
      refetchChats();
    } catch (err) {
      console.error("Failed to delete chat", err);
    }
  };

  if (chatLoading || chatsLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-background text-foreground">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (chatError) {
    return (
      <div className="flex items-center justify-center h-screen bg-background text-foreground">
        <div className="text-destructive">
          Error loading chat. Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-background">
      {/* Sidebar */}
      <div className="w-64 bg-card border-r border-border p-4 flex flex-col">
        <Button
          onClick={createNewChat}
          className="mb-4 w-full bg-primary text-primary-foreground hover:bg-primary/90"
        >
          <Plus className="mr-2 h-4 w-4" /> New Chat
        </Button>
        <ScrollArea className="flex-grow">
          {chats.map((chat) => (
            <div
              key={chat.chatId}
              className="flex items-center justify-between mb-2 group"
            >
              <Button
                variant={chat.chatId === chatId ? "secondary" : "ghost"}
                className="w-full justify-start text-left truncate"
                onClick={() => navigate(`/cultzyme-ai/${chat.chatId}`)}
              >
                <MessageSquare className="mr-2 h-4 w-4 flex-shrink-0" />
                <span className="truncate">{chat.preview || "New Chat"}</span>
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => deleteCurrentChat(chat.chatId)}
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))}
        </ScrollArea>
      </div>

      {/* Main Chat Area */}
      <div className="flex-grow flex flex-col">
        <CardHeader className="border-b bg-card">
          <CardTitle className="text-2xl font-bold">
            CultzymeAI Assistant
          </CardTitle>
        </CardHeader>
        <CardContent className="flex-grow overflow-hidden p-0">
          <ScrollArea className="h-full px-4 py-6">
            {messages.map((message, index) => (
              <div
                key={message.messageId}
                className={`flex ${
                  message.senderType === "USER"
                    ? "justify-end"
                    : "justify-start"
                } mb-4`}
              >
                <div
                  className={`flex items-start max-w-[70%] ${
                    message.senderType === "USER"
                      ? "flex-row-reverse"
                      : "flex-row"
                  }`}
                >
                  <Avatar className="w-8 h-8 mt-1">
                    <AvatarImage
                      src={
                        message.senderType === "USER"
                          ? "/user-avatar.png"
                          : "/bot-avatar.png"
                      }
                    />
                    <AvatarFallback>
                      {message.senderType === "USER"
                        ? userData?.alias?.[0].toUpperCase() || "U"
                        : "AI"}
                    </AvatarFallback>
                  </Avatar>
                  <div
                    className={`mx-2 p-3 rounded-lg ${
                      message.senderType === "USER"
                        ? "bg-primary text-primary-foreground"
                        : "bg-secondary"
                    }`}
                    dangerouslySetInnerHTML={{ __html: message.content }}
                  />
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </ScrollArea>
        </CardContent>
        <Card className="m-4 mt-0 border-t">
          <CardContent className="p-4">
            <div className="flex items-center space-x-2">
              <Input
                ref={inputRef}
                type="text"
                placeholder="Type your message..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                className="flex-grow"
                disabled={chatIsPrinting}
              />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Dialog
                      open={isSettingsOpen}
                      onOpenChange={setIsSettingsOpen}
                    >
                      <DialogTrigger asChild>
                        <Button variant="outline" size="icon">
                          <Settings className="h-4 w-4" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Chat Settings</DialogTitle>
                        </DialogHeader>
                        <div className="py-4">
                          <div className="mb-4">
                            <Label className="text-sm font-medium mb-1 block">
                              Temperature: {temperature.toFixed(2)}
                            </Label>
                            <Slider
                              value={[temperature]}
                              onValueChange={(value) =>
                                setTemperature(value[0])
                              }
                              max={1}
                              step={0.01}
                            />
                          </div>
                          <Separator className="my-4" />
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={usePublicData}
                              onCheckedChange={setUsePublicData}
                              id="public-data"
                            />
                            <Label
                              htmlFor="public-data"
                              className="text-sm font-medium"
                            >
                              Use Public Data
                            </Label>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Chat Settings</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <Button
                onClick={handleSendMessage}
                disabled={chatIsPrinting}
                size="icon"
                className="bg-primary text-primary-foreground hover:bg-primary/90"
              >
                <Send className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default FullScreenChatbot;
