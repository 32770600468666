import { AiOutlineLoading as LoadingIcon } from "react-icons/ai";
import styles from "./LoadingText.module.css";

const LoadingText = () => {
  return (
    <span className={styles["loading-icon"]}>
      <LoadingIcon />
    </span>
  );
};

export default LoadingText;
